html, body {
    width: 100%;
    overflow-x: hidden;
}

@font-face {
    font-family: 'FontAwesome';
    font-display: swap !important;
}

.disabledTab {
    pointer-events: none;
}

.error {
    font-size: .875rem;
    color: #fa755a;
}

#card-errors {
    color: #fa755a;
}

small {
    font-size: 12px;
    font-style: italic;
}

.select {
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    border: 0;
    background: #ECEFF3;
    padding: 0 15px;
}

.pac-target-input {
    width: 100%;
    height: 40px;
    margin-bottom: 0;
    border: 0;
    background: #ECEFF3;
    padding: 0 15px;
}

.addressFields {
    display: none;
}

.cursor {
    cursor: pointer;
}

.cursor:hover {
    color: #2196f3;
}